import React from 'react';

import Seo from '../../components/Seo';
import Layout from '../../components/layout/Layout';
import SplitSection from '../../components/SplitSection';
import Callout from '../../components/Callout';
import CalloutSimple from '../../components/CalloutSimple';

import team from '../../assets/drawings/team.png';
import prospects from '../../assets/drawings/prospects.png';
import customers from '../../assets/drawings/customers.png';
import access from '../../assets/drawings/access.png';

export default () => {
  const title = 'Share your project with stakeholders';
  const description =
    'With Strio, share what you coded, whether it be a commit, a feature or a whole product! Easily create ephemeral environments that you can use to present demos of your work.';
  const url = 'https://strio.app/usecases/demos';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <div className="bg-lownight md:h-20 h-16" />
      <section className="relative pa-0 overflow-hidden">
        <div className="squared-background-left" />
        <div className="squared-background-right" />

        <div className="flex items-center relative z-1 lg:my-40 mt-32">
          <div className="container py-6 px-4">
            <div className="text-center md:text-left text-lownight">
              <h1 className="lg:mt-10 text-4xl lg:text-5xl xl:text-6xl font-bold leading-none tracking-tight">
                Share your project with stakeholders
              </h1>
              <p className="font-normal leading-snug text-base lg:text-2xl mt-12">
                With Strio, share what you coded, whether it be a commit, a feature or a whole
                product! Easily create ephemeral environments that you can use to present demos of
                your work.
              </p>
              <Callout />
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white shadow-inner">
        <SplitSection
          reverseOrder
          primarySlot={
            <img src={team} style={{ height: '200px' }} alt="team icon" className="mx-auto my-8" />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Review for your team
              </h3>
              <div className="mt-6 text-xl leading-relaxed text-lownight">
                Strio facilitates access to the shipped code within your team. Allow anyone who is
                interested in reviewing the code to check the result on a temporary environment
                created with the Pull Request. Let your Tech Lead make this final check before
                release, or your Product Owner have a look at this new feature currently being
                built.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={prospects}
              style={{ height: '200px' }}
              alt="prospects icon"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Demos for your prospects
              </h3>
              <div className="mt-6 text-lg leading-relaxed">
                Ephemeral environments can be used to make demos to your leads. Sales teams can now
                generate a personnalized simulation of your product to your potential customer, to
                show them what the product would look like for their specific use.
              </div>
            </div>
          }
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={customers}
              style={{ height: '200px' }}
              alt="customers icon"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Demos and isolation for your customers
              </h3>
              <div className="mt-6 text-xl leading-relaxed text-lownight">
                Show your customers what this new POC would look like on their platform with a sneak
                preview. Easily isolate each customer&apos;s content.
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={access}
              style={{ height: '200px' }}
              alt="Access rights icon"
              className="mx-auto my-8"
            />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-4xl font-semibold leading-tight font-display text-lownight">
                Grant specific access rights to your team members
              </h3>
              <div className="mt-6 text-lg leading-relaxed">
                Specify permissions for each member of your team, to only give access to what they
                need. Choose the type of actions that can be performed, and the resources where they
                can be performed. These permissions can be defined by user, team and role.
              </div>
            </div>
          }
        />
      </section>
      <section className="bg-gray-100">
        <CalloutSimple />
      </section>
    </Layout>
  );
};
