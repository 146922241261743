import React from 'react';
import { Link } from 'gatsby';

const Callout = () => {
  return (
    <div className="mt-4 py-3 lg:py-6 overflow-hidden flex items-center justify-center md:justify-start">
      <div className="inline-flex rounded-md shadow">
        <Link
          className="text-base w-full inline-flex items-center justify-center px-8 py-3 border border-transparent leading-6 font-bold tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
          to="/contact"
        >
          Get started
        </Link>
      </div>
    </div>
  );
};

export default Callout;
